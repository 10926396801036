body{
  /* background-color: #d3cdcc !important; */
  background-image: url(../../../public/assets/BG.png);
  width: 200x;
  height: 200px;
  background-size: cover;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(135deg, rgb(115, 24, 24) 0%, rgb(168, 43, 43) 69%, rgb(168, 83, 44) 89%) !important;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}

.react-datepicker-wrapper {
  width: 100%;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
          135deg,
          rgb(114, 37, 37) 0%,
          rgb(173, 52, 52) 69%,
          rgb(168, 83, 43) 89%
  ) !important;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}



/*end home page styles*/
